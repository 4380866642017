import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useLangContext } from '../../context/lang.context'
import Headline from '../../components/Headline'
import Tiles from '../../components/Tiles'
import Section from '../../components/Section'

const content = {
  title: {
    pl: 'Oddziały',
    en: 'Offices',
  },
}

const Offices = () => {
  const { lang } = useLangContext()

  const { children } = useStaticQuery(graphql`
    {
      children: allContentfulSpedycja(
        sort: { fields: city }
        filter: { city: { ne: null } }
      ) {
        nodes {
          city
          slug
          node_locale
          noCreatePage
          image {
            gatsbyImageData(width: 480, quality: 100, formats: [JPG])
          }
        }
      }
    }
  `)

  const links = children.nodes
    .filter((node) => node.node_locale === 'pl')
    .map((node) => ({
      slug: node.noCreatePage ? node.slug.slice(0, -2) : node.slug,
      name: node.city,
      image: node.image,
    }))

  return (
    <>
      <Section top>
        <Headline h={2} title={content.title[lang]} large gradient />
      </Section>
      <Tiles data={links} marginMinimal small />
    </>
  )
}

export default Offices
