import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Headline from '../../components/Headline'
import Section from '../../components/Section'
import Tiles from '../../components/Tiles'

const Csr = () => {

  const { children } = useStaticQuery(graphql`
    {
      children: allContentfulCsr(sort: { fields: subtitle }) {
        nodes {
          title
          slug
          node_locale
          subtitle
          image {
            gatsbyImageData(width: 480, quality: 100, formats: [JPG])
          }
        }
      }
    }
  `)

  const links = children.nodes
    .filter((node) => node.node_locale === 'pl')
    .map((node) => ({
      slug: 'csr/' + node.slug,
      name: node.title,
      image: node.image,
      subtitle: node.subtitle,
    }))

  return (
    <>
      <Section top>
        <Headline h={2} title={'CSR'} large gradient />
      </Section>
      <Tiles data={links} marginMinimal />
    </>
  )
}

export default Csr
