import { age } from '../utils/index'

export const seo = {
  url: 'poznaj-nas',
  title: {
    pl: `Poznaj jedną z najlepszych spedycji w Polsce. ${age}-letnie doświadczenie w branży`,
    en: `Discover one of the best forwarding in Poland. ${age}-years of experience in the industry`,
  },
  desc: {
    pl: 'Najlepsza spedycja w Polsce, transport międzynarodowy, firma TSL z wieloletnim doświadczeniem. Poznaj historię Omida Logistics.',
    en: 'The best forwarding in Poland, international transport, TSL company with many years of experience. Discover the history of Omida Logistics',
  },
  keywords: ['omida', 'logistics', 'transport'],
}

export const intro = {
  title: {
    pl: 'Poznaj Nas',
    en: 'Discover Us',
  },
  desc: {
    pl: `Poznaj firmę Omida Logistics - najbardziej rozpoznawalną, polską markę TSL.`,
    en: `Welcome to Omida Logistics - the most recognizable Polish brand of TSL.`,
  },
  button: {
    text: {
      pl: 'Czytaj więcej',
      en: 'Read more',
    },
    action: 'SCROLL',
  },
}

export const main = {
  body: [
    {
      headline: {
        pl: 'Najlepsza spedycja w Polsce, transport międzynarodowy, firma TSL z wieloletnim doświadczeniem. Poznaj historię Omida Logistics.',
        en: 'The best forwarding in Poland, international transport, TSL company with many years of experience. Learn the history of Omida Logistics.',
      },
      texts: [
        {
          pl: `Struktura marki składa się z 32 oddziałów w całej Polsce i Europie, reprezentowanych przez ponad 250 spedytorów oraz sieć kilkuset krajowych i europejskich przewoźników.`,
          en: `We are a Polish company who is Road Transport Leader in both Poland and Europe. Omida Logistics is a brand of the OMIDA Group, which was established in 2010. Our structure consists of 20 branches located all over Poland, which is represented by over 200 forwarders, and a network of several hundred carriers in Poland and Europe.`,
        },
        {
          pl: `Nieprzerwanie od ${age} lat realizujemy strategię dynamicznego rozwoju. Założycielami Omida Logistics jest Bartłomiej Glinka i Marek Rostkowski. Centrala Omida Logistics znajduje się w gdańskim ośrodku biznesowym - Olivia Centre w budynku Olivia Star na 25 piętrze.`,
          en: `Throughout these ${age} years, we have been continuously implementing the strategy of dynamic development. The founders of Omida Logistics are Bartłomiej Glinka and Marek Rostkowski. The headquarters of Omida Logistics is located in the Olivia Centre in the Olivia Star building on the 9th floor.`,
        },
      ],
    },
    {
      headline: {
        pl: 'Historia polskiej firmy transportowej - Omida Logistics',
        en: 'History of the Polish transport company - Omida Logistics',
      },
      texts: [
        {
          pl: '<span>Nasza historia rozpoczęła się w <strong>Gdańsku</strong>. Założyciele naszej firmy, <strong>Bartłomiej Glinka</strong> oraz <strong>Marek Rostkowski</strong> otwierają firmę Omida Logistics i tworzą kilkuosobowy oddział w biurze z widokiem na Westerplatte. W ciągu 3 lat stworzyliśmy kilka oddziałów w <strong>Warszawie</strong>, <strong>Poznaniu</strong>, <strong>Katowicach</strong> i <strong>Sopocie</strong>. Kierując się zasadą, ze Omida to ludzie po 5 latach działalności w naszej firmie zatrudnialiśmy <strong>ponad 100 osobowy zespół specjalistów</strong>. W 2015 roku swoją siedzibę przenieśliśmy do najnowocześniejszego centrum biznesowego na Pomorzu - <strong>Olivia Business Centre w Gdańsku</strong>.</span>',
          en: '<span>Our story began in <strong>Gdańsk</strong>. The founders of our company, <strong>Bartłomiej Glinka</strong> and <strong>Marek Rostkowski</strong>, open Omida Logistics and create a branch of several people in the office with a view of Westerplatte. Within 3 years we created several branches in <strong>Warsaw</strong>, <strong>Poznań</strong>, <strong>Katowice</strong> and <strong>Sopot</strong>. Guided by the principle that Omida is people, after 5 years of operation in our company, we employed a <strong>team of over 100 specialists</strong>. In 2015, we moved our headquarters to the most modern business center in Pomerania - <strong>Olivia Business Center in Gdańsk</strong>.</span>',
        },
        {
          pl: '<span>Dzięki unikatowej ścieżce kariery, liderzy poszczególnych oddziałów stworzyli strukturę spółek partnerskich specjalizujących się w niszach transportowych takich jak: <strong>transport ponadgabarytowy</strong>, <strong>transport militarny</strong>, <strong>transport morski</strong>, <strong>transport lotniczy</strong>, <strong>transport w krajach skandynawskich</strong>, <strong>transport high value</strong>. </span>',
          en: '<span>Thanks to the unique career path, the leaders of inspanidual branches have created a structure of partner companies specializing in transport niches such as: <strong>oversized transport</strong>, <strong>military transport</strong>, <strong>sea transport</strong>, <strong>air transport</strong>, <strong>transport in the Scandinavian countries</strong>, <strong>high value transport</strong>.</span>',
        },
        {
          pl: `<span>Bartłomiej Glinka i Marek Rostkowski zdecydowali o utworzeniu marki <a href="https://omida-group.com">OMIDA Group</a>.<br/>Jako główny claim Grupy Omida utworzono hasło <strong>“Together We Are Strong”</strong>.<br/><br/>
          Dziś OMIDA Group składa się z:
            <ul>
              <li><a href="/">Omida Logistics</a></li>
              <li><a href="https://seaandair.pl">Omida Sea And Air</a></li>
              <li><a href="https://vls.pl/">Virtus Logistics</a></li>
              <li><a href="https://starklog.pl">Stark Log</a></li>
              <li><a href="https://cargonite.pl">CARGONITE</a></li>
              <li><a href="https://bpochampions.pl">BPO Champions</a></li>
            </ul>
            </span>`,
          en: `
          <span> Bartłomiej Glinka and Marek Rostkowski decided to create the <a href="https://omida-group.com">OMIDA Group</a> brand.<br/>As the main claim of the OMIDA Group, the slogan <strong>“Together We Are Strong ”</strong>.<br/><br/>
           Today the OMIDA Group consists of:
             <ul>
               <li><a href="/">Omida Logistics</a></li>
               <li><a href="https://seaandair.pl">Omida Sea And Air</a></li>
               <li><a href="https://vls.pl/">Virtus Logistics</a></li>
               <li><a href="https://starklog.pl">Stark Log</a></li>
               <li><a href="https://cargonite.pl">CARGONITE</a></li>
               <li><a href="https://bpochampions.pl">BPO Champions</a></li>
             </ul>
             </span>
          `,
        },
        {
          pl: `
          <span>W 2019 roku otworzyliśmy <a href="/logistyka-kontraktowa/">magazyn OMIDA Group w Wypędach pod Warszawą</a> i w 2022 podjęliśmy decyzje o <a href="/blog/magazyn-dla-ecommerce-nowa-przestrzen/">dwukrotnym powiększeniu obszaru powierzchni magazynowej</a>, w której realizujemy całość usług związanych z obsługą procesów logistycznych dla sklepów <a href="/ecommerce/">ecommerce</a>.
          </span>`,
          en: `
          <span>In 2019, we opened the <a href="/logistyka-kontraktowa/">OMIDA Group magazine in Wypędy near Warsaw </a>and in 2022 we decided to <a href="/blog/magazyn-dla-ecommerce-nowa-przestrzen/">doubling the area of warehouse space</a>, in which we provide all services related to the handling of logistic processes for <a href="/ecommerce/">ecommerce</a> stores.
          </span>`,
        },
      ],
    },
    {
      headline: {
        pl: 'Zyskaj doświadczonego partnera transportowego w Polsce i Europie',
        en: 'Gain an experienced transport partner in Poland and Europe',
      },
      texts: [
        {
          pl: `
          <span>
            Specjalizujemy się w <a href="/transport-calopojazdowy/">transporcie drogowym całopojazdowym FTL</a>. Organizujemy <a href="/transport-miedzynarodowy/">transport na terenie całej Europie</a>. Dzięki naszym oddziałom realizujemy transport dla branży:
            <ul>
              <li><a href="/transport-automotive/">automotive</a></li>
              <li><a href="/transport-mebli/">meblarskiej</a></li>
              <li><a href="/transport-surowcow/">produkcyjnej</a></li>
              <li><a href="/transport-budownictwo/">budowlanej</a></li>
              <li><a href="/transport-spozywczy/">spożywczej</a></li>
              <li><a href="/transport-elektroniki/">elektronicznej</a></li>
              <li><a href="/transport-gaming/">gamingowej</a></li>
              <li><a href="/transport-fashion/">odzieżowej </a></li>
              <li><a href="/transport-chemia/">chemicznej </a></li>
              <li><a href="/transport-branze/">oraz wielu innych</a></li>
            </ul>
          </span>
        `,
          en: `
          <span>
            We specialize in <a href="/transport-calopojazdowy/">FTL road transport</a>. We organize <a href="/transport-miedzynarodowy/">transport throughout Europe</a>. Thanks to our branches, we provide transport for the industry:
            <ul>
              <li><a href="/transport-automotive/">automotive</a></li>
              <li><a href="/transport-mebli/">furniture</a></li>
              <li><a href="/transport-source/">production</a></li>
              <li><a href="/transport-building/">construction</a></li>
              <li><a href="/food- transport/">grocery</a></li>
              <li><a href="/transport-electronics/">electronic</a></li>
              <li><a href="/transport-gaming/">gaming</a></li>
              <li><a href="/transport-fashion/">clothing</a></li>
              <li><a href="/transport-chemia/">chemical</a></li>
              <li><a href="/transport-branze/">and many more</a></li>
            </ul>
          </span>
          `,
        },
        {
          pl: '<span>Oprócz transportu drogowego całopojazdowego oraz <a href="/transport-drobnicowy/">drobnicowego</a> realizujemy ekologiczny <a href="/transport-intermodalny/">transport&nbsp;intermodalny</a> oraz transport rol papieru - <a href="/paperliner/">paperliner</a>.</span>',
          en: '<span>In addition to road FTL transport and <a href="/transport-drobnicowy/">groupage LTL transport</a> we provide ecological <a href="/transport-intermodalny/">intermodal transport</a><br/>and transport of paper rolls - <a href="/paperliner/">paperliner</a>.</span>',
        },
        {
          pl: `<span>W naszym portfolio znajdują się takie firmy jak: 
          <strong>Electrolux</strong>, 
          <strong>Kreisler</strong>, 
          <strong>Michelin</strong>, 
          <strong>Procter and Gamble</strong>, 
          <strong>Unilever</strong>, 
          <strong>Żabka</strong>, 
          <strong>Żywiec Zdrój</strong>
          i wiele innych firmy polskich i międzynarodowych. </span>`,
          en: `
          <span>Our portfolio includes companies such as:
           <strong>Electrolux</strong>,
           <strong>Kreisler</strong>,
           <strong>Michelin</strong>,
           <strong>Procter and Gamble</strong>,
           <strong>Unilever</strong>,
           <strong>Żabka</strong>,
           <strong>Żywiec Zdrój</strong>
           and many other Polish and international companies.</span>
           `,
        },
      ],
    },
  ],
}

export const video = {
  title: {
    pl: '',
    en: '',
  },
  desc: {
    pl: '',
    en: '',
    // pl: '(Październik 2020) Wiele godzin planowania, realizacji i montażu. Wszystko po to, aby podsumować dotychczasowe 10 lat w branży TSL. O tym jak powstała #OmidaGroup i jakie decyzje trzeba było podjąć, aby stać się jedną z najbardziej rozpoznawalnych marek w branży TSL w Polsce, opowiedzieli Marek Rostkowski i Bartłomiej Glinka, Prezesi Omida To wyjątkowy czas dla naszej firmy, zachęcamy do komentowania i udostępniania - pokażmy wspólnymi siłami jak silna, jest marka OMIDA Group.',
    // en: "(October 2020) Many hours of planning, recording and video editing. All this to summarize the 10 years in the TSL industry to date. Marek Rostkowski and Bartłomiej Glinka, Presidents of Omida, told about how #OmidaGroup was created and what decisions had to be made to become one of the most recognizable brands in the TSL industry in Poland. This is a special time for our company, we encourage you to comment and share - let's show together how strong the OMIDA Group brand is.",
  },
  url: 'https://www.youtube.com/embed/P3S5QVpjWmI',
}
