import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { seo, intro, main, video } from '../content/poznaj-nas'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import { useLangContext } from '../context/lang.context'
import Seo from '../components/Seo'
import Main from '../components/Main'
import Offices from '../sections/poznaj-nas/Offices'
import Chiefs from '../sections/poznaj-nas/Chiefs'
import Awards from '../sections/poznaj-nas/Awards'
import Certificates from '../sections/poznaj-nas/Certificates'
import Csr from '../sections/poznaj-nas/Csr'
import BlogEvents from '../sections/poznaj-nas/BlogEvents'
import Partners from '../sections/Partners'
import Video from '../components/Video'

const PoznajNas = () => {
  const { lang } = useLangContext()

  const { image } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/poznaj-nas.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        image={'images/poznaj-nas.jpg'}
        url={seo.url}
      />
      <Intro data={intro} image={image.childImageSharp} />
      <Main data={main} h={1} />
      <Video data={video} />
      <Chiefs />
      <Awards />
      <Certificates />
      <Offices />
      <Csr />
      <BlogEvents />
      <Partners />
    </Layout>
  )
}

export default PoznajNas
