import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useLangContext } from '../../context/lang.context'
import Headline from '../../components/Headline'
import Section from '../../components/Section'
import Tiles from '../../components/Tiles'

const content = {
  title: {
    pl: 'Wydarzenia',
    en: 'Events',
  },
}

const BlogEvents = () => {
  const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    {
      events: allContentfulBlog(
        filter: {
          blog_categories: {
            elemMatch: { blog_category: { eq: "Wydarzenia" } }
          }
        }
        sort: { fields: date, order: DESC }
      ) {
        edges {
          node {
            title
            node_locale
            blog_slug
            image {
              gatsbyImageData(width: 480, quality: 100, formats: [JPG])
            }
          }
        }
      }
    }
  `)

  const links = query.events.edges
    .filter(({ node }) => node.node_locale === 'pl')
    .map(({ node }) => ({
      slug: 'blog/' + node.blog_slug,
      name: node.title,
      image: node.image,
    }))

  return (
    <>
      <Section top>
        <Headline h={2} title={content.title[lang]} large gradient />
      </Section>
      <Tiles data={links} marginMinimal />
    </>
  )
}

export default BlogEvents
